export const sidebar = {
    Analytics: "Analyse",
    PAGES: "Pages",
    Users: "Utilisateurs",
    UserVerification: "Vérification des utilisateurs",
    Support: "Support",
    Notifications: "Notifications"
  };
  
  export const banner = {
    TotalUsers: "Utilisateur total",
    PaidUsers: "Utilisateurs payants",
    Lastweekanalytics: "Analyse de la semaine dernière",
    ActiveUsers: "Utilisateurs actifs",
    InactiveUsers: "Utilisateurs inactifs"
  };
  



  export const userDetails = {
    USER: "UTILISATEUR",
    AGE: "ÂGE",
    BIBLEVERSION: "VERSION DE LA BIBLE",
    DENOMINATION: "DÉNOMINATION",
    ACTION: "ACTION"
  };


  export const userProfile = {
    MyProfile: "Mon Profil",
    Settings: "Paramètres",
    FAQ: "FAQ"
  };



  

  
  export const loginWords = {
    WelcomeMessage: "Bienvenue sur SmartBible ! 👋🏻",
    SignInPrompt: "Veuillez vous connecter à votre compte et commencer l'aventure",
    Email: "Email",
    Password: "Mot de passe",
    Login: "Se connecter"
  };



  export const ForgotWords = {
    ForgotPassword: "Mot de passe oublié 🔒",
    ResetInstructions: "Entrez votre email et nous vous enverrons des instructions pour réinitialiser votre mot de passe",
    EnterYourEmail: "Entrez votre email",
    SendResetLink: "Envoyer le lien de réinitialisation",
    BackToLogin: "Retour à la connexion",
  };

  export const forgotValidation = {
    InvalidEmail: "Adresse e-mail invalide",
    Required: "Requis"
};



export const resetwords = {
  ResetPasswordTitle: "Réinitialiser le mot de passe 🔒",
  PasswordRequirement: "Votre nouveau mot de passe doit être différent des mots de passe utilisés précédemment",
  NewPassword: "Nouveau mot de passe",
  ConfirmPassword: "Confirmer le mot de passe",
  SetNewPassword: "Définir le nouveau mot de passe",
  BackToLogin: "Retour à la connexion"
};


export const resetPassValidation = {
  PasswordMinLength: "Le mot de passe doit contenir au moins 6 caractères",
  Required: "Requis",
  PasswordsMustMatch: "Les mots de passe doivent correspondre"
};



  