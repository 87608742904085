import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminAPI } from "../../Services/AdminApi";

// ===== Forget Password =======

export const forgetPass = createAsyncThunk("forgetPass", async (details) => {
  const data = await AdminAPI.post(`/forgot_password`, details);
  return data;
});

// ====== For Users listing =====

export const usermanagement = createAsyncThunk(
  "usermanagement",
  async (details) => {
    let url = `/user_listing?page=${details?.page}&limit=${details?.limit}`;

    if (details.search) {
      url += `&search=${details.search}`;
    }

    const data = await AdminAPI.get(url);
    console.log(data);
    return data;
  }
);

// =======  Dowanload Users List ============

export const DowanloadUserxlsv = createAsyncThunk(
  "DowanloadUserxlsv",
  async () => {
    const data = await AdminAPI.get(`/user_listing`);
    console.log(data);
    return data;
  }
);

// ======== Delete User account api =============

export const DeleteUser = createAsyncThunk("DeleteUser", async (details) => {
  const data = await AdminAPI.delete(`/deleteAccount/${details?.id}`);
  return data;
});


// ======= Reset Password ===========

export const ResetPass = createAsyncThunk(
  "ResetPass",
  async(details) => {
      const data = await AdminAPI.post(`/resetPassword`,details);
     return data;
  } 
);
